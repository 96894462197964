import { Menu, Transition } from '@headlessui/react';
import { CheckCircleIcon, DotsHorizontalIcon, ExclamationCircleIcon, ExclamationIcon, LightningBoltIcon, PencilIcon, TrashIcon, DuplicateIcon } from '@heroicons/react/outline';
import { useClassNames, useInputFocusSelect } from '@metaforcelabs/metaforce-core';
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { WorkflowContext, WorkflowStepContext } from '../../../../contexts';
import { workflowStepType, workflowAppType } from '../../../../utils/constants';
import WorkflowEventAppIcon from '../workflowAppIcon';
import { useWorkflowStep } from '../../../../hooks/useWorkflowStep';
import DndDragHandle from '../../../../components/DragAndDrop/dndDragHandle';

export default function WorkflowItemHeader({ workflowStep, stepIdx, activeStep }) {
    const [triggerNameInputRef, setTriggerNameInputRef] = useInputFocusSelect();
    const [isRenaming, setIsRenaming] = useState(false);
    const [stepName, setStepName] = useState(workflowStep.name);
    const { classNames } = useClassNames();
    const workflowContext = useContext(WorkflowContext);
    const { isDragOverlayComponent } = useContext(WorkflowStepContext);
    const [stepState, setStepState] = useState({});
    const [stepErrors, setStepErrors] = useState([]);
    const { canCopy, canMove } = useWorkflowStep();

    const handleWorkflowStepNameChange = ({ value }) => {
        setStepName(prev => {
            return value;
        });
    }

    useEffect(() => {
        setStepErrors(workflowContext.validationErrors[workflowStep.id] || []);
    }, [workflowContext.validationErrors, workflowStep.id])

    useEffect(() => {
        const errors = stepErrors.filter(e => e.type === "error");
        const warnings = stepErrors.filter(e => e.type === "warning");

        let stateObj = { type: "ok", message: "Ok" };

        if (errors.length > 0) {
            stateObj = { type: "error", message: "One or more errors" }
        } else if (warnings.length > 0) {
            stateObj = { type: "warning", message: "One or more warnings" }
        }
        setStepState(prev => stateObj);

    }, [stepErrors])


    return (
        <div className={classNames("flex justify-between space-x-4 items-center px-4 py-5 sm:px-6",
            workflowStep.appEvent ? "bg-white" : "bg-brand-pink",
            activeStep ? "rounded-t-lg" : "rounded-lg")}>
            <div className={classNames("flex items-center space-x-2 sm:space-x-4 w-full sm:w-3/4")}>
                {
                    workflowStep.appEvent ? (
                        <>
                            <WorkflowEventAppIcon iconKey={workflowStep.appEvent.appIcon} className="flex-none rounded-lg border h-14 w-14 p-2 shadow" title={stepState?.message} stateBadgeType={stepState?.type} />
                            <div className="w-full">
                                <div>
                                    <span className="text-gray-600">
                                        {
                                            workflowStep.stepType === workflowStepType.trigger && ("Trigger")
                                        }
                                        {
                                            workflowStep.stepType === workflowStepType.action && ("Action")
                                        }
                                    </span>
                                </div>
                                {
                                    isRenaming ? (
                                        <div className="flex rounded-md shadow-sm">
                                            <input
                                                type="text"
                                                name="trigger-name"
                                                id="trigger-name"
                                                autoComplete="off"
                                                ref={triggerNameInputRef}
                                                className="flex-1 py-1 font-semibold focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-none rounded-l-md border-gray-300"
                                                value={stepName}
                                                onChange={e => handleWorkflowStepNameChange(e.target)}
                                                onBlur={e => {
                                                    workflowContext.updateWorkflowStepName(workflowStep.id, stepName);
                                                    setIsRenaming(false);
                                                }}
                                            />
                                            <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                                <PencilIcon className="h-5 w-5" />
                                            </span>
                                        </div>
                                    ) : (

                                        <div><h3 className="py-1 font-semibold border border-white" onClick={e => {
                                            if (activeStep) {
                                                setIsRenaming(true);
                                                setTimeout(() => {
                                                    setTriggerNameInputRef()
                                                }, 50);
                                            }
                                        }}>{stepIdx + 1}. {workflowStep?.name}</h3></div>
                                    )
                                }
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex-none rounded-lg border h-14 w-14 p-2 bg-brand-pink shadow">
                                {
                                    workflowStep.stepType === workflowStepType.trigger && (
                                        <LightningBoltIcon className="h-full w-full text-white transform rotate-90" />
                                    )
                                }
                                {
                                    workflowStep.stepType === workflowStepType.action && (
                                        <LightningBoltIcon className="h-full w-full text-white transform" />
                                    )
                                }
                            </div>
                            <div className="w-full">
                                {
                                    workflowStep.stepType === workflowStepType.trigger && (
                                        <>
                                            <div><span className="text-white font-semibold text-lg">Trigger</span></div>
                                            <div><h3 className=" text-white text-sm border border-brand-pink">A trigger is an event that starts your workflow</h3></div>
                                        </>
                                    )
                                }
                                {
                                    workflowStep.stepType === workflowStepType.action && (
                                        <>
                                            <div><span className="text-white font-semibold text-lg">Action</span></div>
                                            <div><h3 className=" text-white text-sm border border-brand-pink">An action is an event performed after the workflow starts</h3></div>
                                        </>
                                    )
                                }

                            </div>
                        </>
                    )
                }
            </div>
            <div className='flex gap-x-2'>
                {canMove(workflowStep) && (
                    <DndDragHandle
                        workflowStep={workflowStep}
                        isDragOverlayComponent={isDragOverlayComponent}
                        disabled={workflowContext.dragAndDropDisabled}
                    />
                )}
                {
                    <div className="relative z-10">
                        <Menu>
                            {(workflowStep.appEvent || stepIdx > 0) && (
                                <Menu.Button className="w-full">
                                    <div className={classNames("hover:shadow rounded h-10 w-10 p-2",
                                        workflowStep.appEvent ? "text-gray-600 hover:bg-gray-100" : "text-white hover:bg-brand-pink-hover"
                                    )}>
                                        <DotsHorizontalIcon className="" />
                                    </div>
                                </Menu.Button>
                            )}
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="z-10 absolute right-0 w-56 mt-1 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {
                                        workflowStep.appEvent && (
                                            <div className="p-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                                                active ? "text-brand-pink font-semibold" : "text-gray-900")}
                                                            onClick={() => {
                                                                setIsRenaming(true);
                                                                setTimeout(() => {
                                                                    setTriggerNameInputRef()
                                                                }, 50);
                                                            }}
                                                        >
                                                            <PencilIcon
                                                                className="w-5 h-5 mr-2"
                                                                aria-hidden="true"
                                                            />
                                                            Rename step
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        )
                                    }
                                    {
                                        stepIdx > 0 && (
                                            <div className="p-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                                                active ? "text-brand-pink font-semibold" : "text-gray-900")}
                                                            onClick={() => {
                                                                workflowContext.deleteStep(workflowStep.id);
                                                            }}
                                                        >
                                                            <TrashIcon
                                                                className="w-5 h-5 mr-2"
                                                                aria-hidden="true"
                                                            />
                                                            Delete step
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        )
                                    }
                                    {
                                        canCopy(workflowStep) && (
                                            <div className="p-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            className={classNames("group flex rounded-md items-center w-full px-2 py-2 text-sm",
                                                                active ? "text-brand-pink font-semibold" : "text-gray-900")}
                                                            onClick={() => {
                                                                workflowContext.copyStep(workflowStep.id);
                                                            }}
                                                        >
                                                            <DuplicateIcon
                                                                className="w-5 h-5 mr-2"
                                                                aria-hidden="true"
                                                            />
                                                            Duplicate step
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        )
                                    }
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                }
            </div>
        </div>
    )
}
