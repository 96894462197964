import { useSortable } from "@dnd-kit/sortable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDown } from "@fortawesome/pro-regular-svg-icons";
import { useClassNames } from "@metaforcelabs/metaforce-core";

export default function DndDragHandle({ workflowStep, isDragOverlayComponent, disabled = false }) {
    const { classNames } = useClassNames();
    const {
        attributes,
        listeners,
    } = useSortable({ id: workflowStep.id, disabled: disabled });

    return (
        <button
            type="button"
            id={`dndbutton-${workflowStep?.id}`}
            className={classNames("flex justify-center items-center hover:shadow rounded h-10 w-10 p-2",
                (workflowStep.appEvent ? "text-gray-600 hover:bg-gray-100" : "text-white hover:bg-brand-pink-hover") + (isDragOverlayComponent ? ' cursor-grabbing ' : ' cursor-grab ')
            )}
            aria-label="Drag step"
            title="Drag step"
            {...listeners}
            {...attributes}
        >
            <FontAwesomeIcon className="" icon={faArrowsUpDown} />
        </button>
    )
}